'use strict';

/* exported
  menuChannelsCtrl
 */
function menuChannelsCtrl($scope, $rootScope, $location, channelsService) {

  $scope.filters = {};
  $scope.channelsService = channelsService;

  var locationSearch = $location.search();

  if (locationSearch.search) {
    $scope.filters.search = locationSearch.name;
  }

  if (locationSearch.type) {
    $scope.filters.type = locationSearch.type;
  }

  $scope.$watch('filters.search', function(partner) {
    $rootScope.$broadcast('filters.search', (partner || {}).name);
    var search = $location.search();
    search.name = (partner || {}).name;
    $location.search(search);
  });

  $scope.$watch('filters.type', function(type) {
    $rootScope.$broadcast('filters.type', (type || {}).slug);
    var search = $location.search();
    search.type = (type || {}).slug;
    $location.search(search);
  });
}

/**
 * Created by yuval on 14/12/22.
 */
'use strict';

/* exported
 inventoryChannelsCtrl
 */

function inventoryChannelsCtrl($scope, $rootScope, $location, $uibModal, Restangular, channels, user, channelsService) {
  $scope.typesMap = {
    b2b: 'B2B',
    whitelabel: 'Whitelabel',
    emails: 'Emails',
    paid: 'Paid',
    referrals: 'Referrals',
    organic: 'Organic',
    'organic-social': 'Organic Social',
    'b2b-redirect': 'B2B Redirect',
    'offline': 'Offline',
    group: 'Group',
    affiliate: 'Affiliate',
    bookingAgent: 'Booking Agent',
  };
  $scope.channelsService = channelsService;
  $scope.channels = channels;
  $scope.channelsToShow = channels.slice(0, 20);
  $scope.user = user;
  $scope.totalItems = $scope.channels.length;
  $scope.filters = {
    excludeRestrictedChannels: true,
    exact: true
  };

  var search = $location.search();
  if (search.page && search.page >= 1) {
    $scope.currentPage = search.page;
  } else {
    $scope.currentPage = 1;
  }

  $scope.pageChanged = function() {
    if ($scope.channels) {
      const startIndex = 20 * ($scope.currentPage - 1);
      const endIndex = startIndex + 20;
      $scope.channelsToShow = $scope.channels.slice(startIndex, endIndex);
    }
  };

  $scope.$on('filters.search', function(event, data) {
    if ($scope.filters.search !== data) {
      $scope.filters.search = data;

      Restangular
        .all('v2/channels').getList($scope.filters).then(function(channels) {
        $scope.channels = channels;
        $scope.totalItems = $scope.channels.length;
        $scope.channelsToShow = $scope.channels.slice(0, 20);
        $scope.currentPage = 1;
      });
    }
  });

  $scope.$on('filters.type', function(event, data) {
    if ($scope.filters.type !== data) {
      $scope.filters.type = data;

      Restangular
        .all('v2/channels').getList($scope.filters).then(function(channels) {
        $scope.channels = channels;
        $scope.totalItems = $scope.channels.length;
        $scope.channelsToShow = $scope.channels.slice(0, 20);
        $scope.currentPage = 1;
      });
    }
  });

  $rootScope.$on('channelCreated', function() {
    Restangular
      .all('v2/channels').getList($scope.filters).then(function(channels) {
      $scope.channels = channels;
      $scope.totalItems = $scope.channels.length;
      $scope.channelsToShow = $scope.channels.slice(0, 20);
      $scope.currentPage = 1;
    });
  });

  $scope.$watch('channelsService.selectedChannel', function () {
    $scope.localFilters = {
      selectedSupplier: null
    };
  });

  $scope.openCreatePartner = function (){
    $uibModal
      .open({
        templateUrl: 'views/channels/create-channel.html',
        controller: 'CreateChannelCtrl',
        resolve: {
          Restangular: Restangular,
        }
      });
  };
}

'use strict';

/* exported
 channelsProvider
 */

function channelsProvider() {
  this.$get = ['Restangular', '$rootScope', 'SearchRestangular', 'ProductFormatterService', function (Restangular, $rootScope, SearchRestangular, ProductFormatterService) {
    var channelsService = {
      activeTab: 0,
      selectedChannel: null,
      liveSearchResults: null
    };

    channelsService.setActiveTab = function (index) {
      this.activeTab = index;
    };

    channelsService.clearSearchResults = function() {
      this.liveSearchResults = null;
    };

    channelsService.save = function() {
      Restangular.all('v2/channels/' + channelsService.selectedChannel._id)
        .customPUT(channelsService.selectedChannel)
        .then(function (response) {
          $rootScope.$emit('notify', {type: 'success', title: 'Partner', message: 'Partner updated with success'});
          Object.assign(channelsService.selectedChannel, response);
        })
        .catch(function() {
          $rootScope.$emit('notify', {type: 'error', title: 'Partner', message: 'Error updating partner'});
        });
    };

    channelsService.retrieveServiceForPartner = function (partnerId) {
       let self = this;
       return Restangular
        .one(`v2/channels/${partnerId}?withCompanies=true`)
        .get()
        .then(channel => {
          self.selectedChannel = channel[0];

          return self;
        });
    }

    channelsService.getLiveSearchResults = function(liveResParams) {
      let self = this;
      const formattedDate = moment(liveResParams.date).format('YYYY-MM-DD');

      return SearchRestangular.one('v2', 'trips')
        .get({ 
          fromCity: liveResParams.departure._id, 
          toCity: liveResParams.arrival._id, 
          date: formattedDate,
          utm_source: this.selectedChannel.slug,
          isB2b: true,
          populateStations: true
        }, 
        {
          'X-Distribution-Channel': this.selectedChannel.slug
        })
        .then((trips) => {
          const tripsByProduct = Object.groupBy(trips.data, trip => trip.product._id)

          const parsedProducts = Object.values(tripsByProduct).map(productTrips => {
            const productData = productTrips[0]
            const productTripsByTime = productTrips.sort((a, b) => moment.utc(a.departure.date).diff(moment.utc(b.departure.date)));

            return {
              companyName: productData.operator.name,
              fromName: productData.from.name,
              toName: productData.to.name,
              type: productData.lineType ? productData.lineType : productData.transferType,
              lineClass: productData.lineClass,
              price: productTripsByTime.map(trip => trip.price),
              departureTimes: productTripsByTime.map(trip => trip.departure.date)
            }
          })

           self.liveSearchResults = parsedProducts
          return self
        });
    };

    return channelsService;
  }];



}

'use strict';

/* exported
  editChannelMenuCtrl
 */
function editChannelMenuCtrl($scope, $rootScope, channelsService) {

  $scope.channelsService = channelsService;
  $scope.PARTNER_TAB_INDEX = {
    PROPERTIES: 0,
    INVENTORY_MANAGEMENT: 1,
    LIVE_RESULTS: 2,
  };

  $scope.liveResParams = {};

  $scope.submitLiveSearchRes = () => {
    $scope.errors = {};

    if (!$scope.liveResParams.departure) { $scope.errors.departure = "Departure is required" };
    if (!$scope.liveResParams.arrival) { $scope.errors.arrival = "Arrival is required" };
    if (!$scope.liveResParams.date) { $scope.errors.date = "Departure Date is required" };

    if (!Object.keys($scope.errors).length) {
      channelsService.getLiveSearchResults($scope.liveResParams);
    };
  };

  $scope.$watch('channelsService.activeTab', function (activeTab, previousActiveTab) {
    if (activeTab === previousActiveTab) {
      return;
    }

    if(previousActiveTab == $scope.PARTNER_TAB_INDEX.LIVE_RESULTS) {
      $rootScope.$broadcast('channelsService.activeTab', activeTab);
    }

    var search = $location.search();
    search.cartReference = cartReference;
    $location.search(search);
  });
};

'use strict';

/* exported
  editChannelMenuCtrl
 */

function getPropertiesList(type) {
  switch (type) {
    case 'whitelabel':
      return ['name', 'logos', 'palette', 'isPartnership', 'slug', 'type'];

    case 'b2b':
      return ['name', 'isPartnership', 'slug', 'type', 'redirectApi'];

    case 'b2b-redirect':
      return ['name', 'isPartnership', 'slug', 'type', 'redirectApi', 'utmMedium', 'utmCampaign', 'utmSource'];

    default:
      return ['name', 'isPartnership', 'slug', 'type', 'utmMedium', 'utmCampaign', 'utmSource'];
  }
}

function editChannelCtrl($scope, $rootScope, $uibModal, user, Restangular, channelsService) {
  $scope.channelsService = channelsService;
  $scope.filters = {
    selectedSupplier: null
  };
  $scope.allowedToEditProperties = ['utmMedium' , 'utmCampaign', 'name', 'utmSource', 'logos', 'palette'];
  $scope.propertiesList = getPropertiesList($scope.channelsService.selectedChannel.type);
  $scope.typesMap = {
    b2b: 'B2B',
    whitelabel: 'Whitelabel',
    emails: 'Emails',
    paid: 'Paid',
    referrals: 'Referrals',
    organic: 'Organic',
    'organic-social': 'Organic social',
    'b2b-redirect': 'B2B Redirect',
    'offline': 'Offline',
    group: 'Group',
    affiliate: 'Affiliate',
    bookingAgent: 'Booking Agent',
  };
  $scope.allowEdit = ['organic-social', 'organic', 'paid', 'emails', 'referrals', 'offline', 'group', 'affiliate', 'whitelabel', 'bookingAgent'].includes($scope.channelsService.selectedChannel.type);

  $scope.deleteCompany = function(companyId) {
    $scope.channelsService.selectedChannel.inventoryRules.companies.values =
      $scope.channelsService.selectedChannel.inventoryRules.companies.values.filter(company => company._id !== companyId);
  };

  $scope.checkIfObject = function(value) {
    return value !== null && typeof value === 'object';
  };

  $scope.addNewCompany = function() {
    if (!($scope.channelsService.selectedChannel.inventoryRules.companies.values.some(company => company._id === $scope.filters.selectedSupplier._id))) {
      $scope.channelsService.selectedChannel.inventoryRules.companies.values.push({_id: $scope.filters.selectedSupplier._id, name: $scope.filters.selectedSupplier.name});
    }

    $scope.filters.selectedSupplier = null;
  };

  $scope.hasCompanies = function(channel) {
    return (channel && channel.inventoryRules && channel.inventoryRules.companies && channel.inventoryRules.companies.values.length !== 0);
  };

  $scope.openEditProperty = function (property){
    $uibModal
      .open({
        templateUrl: 'views/channels/edit-property.html',
        controller: 'EditChannelPropertyCtrl',
        resolve: {
          Restangular: Restangular,
          user,
          channel: $scope.channelsService.selectedChannel,
          propertyName: [function () { return property; }],
        }
      });
  };

  $rootScope.$on('propertyUpdated', function(event, channel) {
    $scope.channelsService.selectedChannel = channel;
  });

  $rootScope.$on('channelsService.activeTab', function(event, activeTab) {
    $scope.liveResFilters = {};
    channelsService.clearSearchResults();
  });

  $scope.$on('$destroy', function() {
    channelsService.clearSearchResults()
  });

  $scope.liveResFilters = {};

  $scope.clearLiveResFilters= () => {
    $scope.liveResFilters = {};
  };

  $scope.liveResFilter = function(product) {
    const matchesCompany = !$scope.liveResFilters.company ||
        product.companyName.toLowerCase().includes($scope.liveResFilters.company.toLowerCase());

    const matchesDepartureStation = !$scope.liveResFilters.departureStation ||
        product.fromName.toLowerCase().includes($scope.liveResFilters.departureStation.toLowerCase());

    const matchesArrivalStation = !$scope.liveResFilters.arrivalStation ||
        product.toName.toLowerCase().includes($scope.liveResFilters.arrivalStation.toLowerCase());

    const matchesVehicleType = !$scope.liveResFilters.vehicleType ||
        product.type.toLowerCase().includes($scope.liveResFilters.vehicleType.toLowerCase());

    const matchesVehicleClass = !$scope.liveResFilters.vehicleClass ||
        product.lineClass.toLowerCase().includes($scope.liveResFilters.vehicleClass.toLowerCase());

    return matchesCompany && matchesDepartureStation && matchesArrivalStation &&
          matchesVehicleType && matchesVehicleClass;
  };
}

'use strict';

/* exported
createChannelCtrl
 */

function createChannelCtrl($scope, $rootScope, $uibModalInstance, Restangular) {
  $scope.saveChannel = function() {
    const slug = $scope.channelName.replace(/[ _]/g,'-').toLowerCase();
    Restangular
      .all('v2/channels?ignoreRestrictions=true').post({ name: $scope.channelName, type: $scope.channelType.slug, slug }).then(
      () => {
          $rootScope.$emit('channelCreated');
          $uibModalInstance.close();
        }
    )
      .catch(function(err) {
        $rootScope.$emit('notify', {type: 'error', title: 'Partner', message: err.data.status === 400 ? err.data.message : 'Error creating partner'});
      });
  };

  $scope.close = function () {
    $uibModalInstance.close();
  };
}

'use strict';

/* exported
editChannelPropertyCtrl
 */

function editChannelPropertyCtrl($scope, $rootScope, $uibModalInstance, appConfig, Upload, user, Restangular, channel, propertyName) {
  $scope.newPropertyValue = channel[propertyName];
  $scope.propertyName = propertyName;

  if (propertyName === 'logos'){
    $scope.lightLogo = '';
    $scope.darkLogo = '';
  }

  $scope.checkIfObject = function(value) {
    return value !== null && typeof value === 'object';
  };

  $scope.saveChannel = function() {
    const payload = Object.assign(channel, { [propertyName]: $scope.newPropertyValue });

    Restangular
      .all('v2/channels/' + channel._id).customPUT(payload).then(
      (res) => {
        $rootScope.$emit('propertyUpdated', res);
        $uibModalInstance.close();
      }
    );
  };


  $scope.$watch('lightLogo', function() {
    $scope.upload($scope.lightLogo, 'light');
  });

  $scope.$watch('darkLogo', function() {
    $scope.upload($scope.darkLogo, 'dark');
  });

  $scope.upload = function (pictures, label) {
    if (pictures && pictures.length && !pictures.$error) {

      Upload.upload({
        url: appConfig.imagesServiceBaseUrl + '/upload/files',
        arrayKey: '', // http://stackoverflow.com/questions/32917617/multer-not-accepting-files-in-array-format-gives-unexpected-filed-error
        data: {
          owner: user._id,
          files: pictures,
          types: 'picture'
        }
      }).then(function(resp) {
        var images = resp.data;

        if (images && images[0]) {
          const url = '' + appConfig.cdnImageEndpoint + '/' + images[0]._id + '.' + images[0].ext;
          if (label === 'light') {
            $scope.newPropertyValue.light = url;
            $scope.newPropertyValue.voucherLogo = url;
          } else if (label === 'dark') {
            $scope.newPropertyValue.dark = url;
          }
        }

        $scope.$emit('notify', {type: 'success', title: 'Upload', message: 'Image uploaded with Success'});
      }, function() {
        $scope.$emit('notify', {type: 'error', title: 'Upload', message: 'Failed to upload images'});
      });
    }
  };



  $scope.close = function () {
    $uibModalInstance.close();
  };
}
